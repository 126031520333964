<div class="col mt-3 ">
    <div *ngIf="formModel.actionNote.documentID" class="head_id">
        <h4><b>Author Document: {{formModel.actionNote.documentID}}</b></h4>
    </div>
    <ngb-tabset (tabChange)="saveOnTabChange($event.nextId)" [activeId]="selectedTab" class="companyreport">
        <ngb-tab id="Metadata" title="Metadata">
            <ng-template ngbTabContent>
                <form #documentForm="ngForm" class="mt-3 mt-sm-2 row justify-content-center center-form" id="form" name="form">
                    <div class="col-sm-8 col-md-10 col-lg-8 mdata">
                        <div class="form-group row">
                            <div *ngIf="formEnabled" class="form-group row justify-content-center col-sm-12 d-sm-block top-bt  d-lg-none">
                                <div class="text-right bt">
                                    <button (click)="onSave()" class="btn btn-primary" type="submit">
                    Save
                  </button>
                                </div>
                                <div class=" ml-3 text-right bt">
                                    <button (click)="onClose()" class="btn btn-primary" type="submit">
                    Close document
                  </button>
                                </div>
                            </div>
                            <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Primary Issuer*</label>
                            <div class="col-7 col-lg-9 col-md-9">
                                <div class="issuer">
                                    <ng-select #primaryIssuer="ngModel" (change)="createDocument($event)" [(ngModel)]="formModel.selectedPrimaryIssuer" class="disable-arrow disable-dropdown disable-clear-all" name="primaryIssuer" placeholder="Select primary issuer" required>
                                        <ng-option *ngFor="let issuer of formModel.primaryIssuers" [value]="issuer.issuerID">
                                            {{issuer.issuerName}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <div [hidden]="primaryIssuer.valid || primaryIssuer.pristine" class="invalid-feedback">
                                    Primary Issuer is required
                                </div>
                            </div>
                        </div>
                        <fieldset *ngIf="formEnabled">
                            <div class="form-group row">
                                <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Subject Code*</label>
                                <div class="col-7 col-lg-9 col-md-9 col-form-label label-x">
                                    <div> {{formModel.actionNote.product.subject}} </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Headline*</label>
                                <div class="col-7 col-lg-9 col-md-9">
                                    <input #headline="ngModel" [(ngModel)]="formModel.actionNote.product.title" class="form-control" name="headline" required type="text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-5 col-lg-3 col-md-3 text-lg-right col-form-label">Subheadline*</label>
                                <div class="col-7 col-lg-9 col-md-9">
                                    <input #subheadline="ngModel" [(ngModel)]="formModel.actionNote.product.subTitle" class="form-control" name="subheadline" required type="text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-3 col-md-3 col-sm-12  text-lg-right col-form-label">Primary Author(s)*</label>
                                <div class="col-lg-9 col-md-9 col-sm-12">
                                    <ng-select #primaryAuthor="ngModel" [(ngModel)]="formModel.actionNote.primaryAuthors" [closeOnSelect]="false" [hideSelected]="true" [items]="formModel.primaryAuthors" [multiple]="true" bindLabel="displayName" name="primaryAuthor" placeholder="Select primary authors"
                                        required>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-lg-3 col-md-3 text-lg-right  col-form-label">Primary Industry*</label>
                                <div class="col-3 col-lg-3 col-md-3 col-form-label label-x">
                                    <span *ngIf="formModel.actionNote.primaryIndustries.length > 0">{{formModel.actionNote.primaryIndustries[0].industryName}}</span>
                                    <span *ngIf="formModel.actionNote.primaryIndustries.length == 0">Not Defined</span>
                                </div>
                                <label class="col-3 col-lg-3 col-md-3 text-lg-right  col-form-label">Sector Rating*</label>
                                <div class="col-3 col-lg-3 col-md-3 col-form-label label-x">
                                    <ng-select #recommendation="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.sectorRecommendation" name="sectorRecommendation" required>
                                        <ng-option *ngFor="let recommendation of formModel.sectorRecommendations" [value]="recommendation">
                                            {{recommendation}}
                                        </ng-option>
                                    </ng-select>
                                    <div [hidden]="recommendation.valid || recommendation.pristine" class="invalid-feedback">
                                        Sector Rating is required
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
                                    <div class="row justify-content-end ">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label ">Report Type*</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <ng-select #reportType="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.reportTypeId" name="reportType" required>
                                                <ng-option *ngFor="let reportType of formModel.reportTypeList" [value]="reportType.id">
                                                    {{reportType.value}}
                                                </ng-option>
                                            </ng-select>
                                            <div [hidden]="reportType.valid || reportType.pristine" class="invalid-feedback">
                                                Report type is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="row justify-content-end">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Report Series*</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <ng-select #reportSeries="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.reportSeriesId" name="reportSeries" required>
                                                <ng-option *ngFor="let reportSeries of formModel.reportSeriesList" [value]="reportSeries.id">
                                                    {{reportSeries.value}}
                                                </ng-option>
                                            </ng-select>
                                            <div [hidden]="reportSeries.valid || reportSeries.pristine" class="invalid-feedback">
                                                Report series is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 col-md-6 col-lg-6 ">
                                    <div class="row justify-content-end">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right  text-lg-right  col-form-label">Current
                      Recommendation*</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <ng-select #current="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.recommendation" name="current" placeholder="Select current recommendation" required>
                                                <ng-option *ngFor="let current of formModel.currentRecommendations" [value]="current">
                                                    {{current}}
                                                </ng-option>
                                            </ng-select>
                                            <div [hidden]="current.valid || current.pristine" class="invalid-feedback">
                                                Current recommendation is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="row justify-content-end">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Default Currency*</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <ng-select #selectedCurrency="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.defaultCurrency" name="selectedCurrency" placeholder="Select currency" required>
                                                <ng-option *ngFor="let currency of formModel.currencies" [value]="currency">{{currency}}
                                                </ng-option>
                                            </ng-select>
                                            <div [hidden]="selectedCurrency.valid || selectedCurrency.pristine" class="invalid-feedback">
                                                Default currency is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 col-md-6 col-lg-6 mb-2">
                                    <div class="row justify-content-end ">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Fair Value</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <input #fairValue="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.targetPrice" class="form-control" name="fairValue" type="text">
                                            <div [hidden]="formModel.priceValidator('fairPrice', fairValue)" class="invalid-feedback">
                                                Fair Value can only be number with two decimal
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="row justify-content-end">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Prior Fair Value</label>
                                        <div class="col-7 col-lg-6 col-md-6">
                                            <input #priorFairValue="ngModel" [(ngModel)]="formModel.actionNote.prodIssuers.previousTargetPrice" class="form-control" name="priorFairValue" type="text" [disabled]="true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-6 col-md-6 col-lg-6">
                                    <div class="row justify-content-end">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Current Price</label>
                                        <div class="col-7 col-lg-6 col-md-6 ">
                                            <input #currentPrice="ngModel" [(ngModel)]="formModel.actionNote.currentPrice" class="form-control" name="currentPrice" type="text">
                                            <div [hidden]="formModel.priceValidator('currentPrice', currentPrice)" class="invalid-feedback">
                                                Current Price can only be number with two decimal
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end mt-4 ">
                                        <label class="col-5 col-lg-6 col-md-6 text-lg-right col-form-label">Available for Individual
                      Sale*</label>
                                        <div class="col-7 col-lg-6 col-md-6 ">
                                            <ng-select #availableForIndividualSale="ngModel" [(ngModel)]="formModel.actionNote.product.individualSale" name="availableForIndividualSale" required>
                                                <ng-option [value]="'Yes'">Yes</ng-option>
                                                <ng-option [value]="'No'">No</ng-option>
                                            </ng-select>
                                            <div [hidden]="availableForIndividualSale.valid || availableForIndividualSale.pristine" class="invalid-feedback">
                                                Availability selection is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6" [hidden]="formModel.actionNote.product.individualSale !== 'Yes'">
                                    <div class="row justify-content-end">
                                        <label class="col-sm-4 col-5 col-md-6 col-lg-6 text-lg-right col-form-label">Single Report Price*</label>
                                        <div class="col-sm-6 col-md-6 col-7 col-lg-6">
                                            <input #singleReportPrice="ngModel" [(ngModel)]="formModel.actionNote.product.singleReportPrice" class="form-control" name="singleReportPrice" type="text">
                                            <div [hidden]="formModel.priceValidator('singleReportPrice', singleReportPrice)" class="invalid-feedback">
                                                Single Report Price can only be number with two decimal
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-5 col-lg-3 col-md-3 text-lg-right  col-form-label">Upload financial Model</label>
                                <div  class="col-2 col-lg-2 col-md-2 col-form-label label-x" >
                                  <input #xlxsInput id="finModel" file-model="finModel" type="file" name="financialModel" [disabled]="formModel.actionNote.readOnly" (change)="changexlxs($event, xlxsInput, excelUpload)">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Company Description</label>
                                <div class="col-sm-9 col-lg-9">
                                    <textarea [(ngModel)]="formModel.actionNote.prodIssuers.companyDescription" class="form-control" name="companyDescription" rows="4"></textarea>
                                </div>
                            </div>
            
                 
                            <div class="form-group row">
                                <label class="col-sm-3 col-lg-3 text-lg-right col-form-label">Abstract</label>
                                <div class="col-sm-9 col-lg-9">
                                    <textarea [(ngModel)]="formModel.actionNote.product.abstract" class="form-control" name="abstract" rows="4"></textarea>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div *ngIf="formEnabled" class="form-group row justify-content-center col-sm-12">
                        <div class="text-right">
                            <button (click)="onSave()" class="btn btn-primary" type="submit" [disabled]="formModel.actionNote.readOnly">
                Save
              </button>
                        </div>
                        <div class=" ml-3 text-right">
                            <button (click)="onClose()" class="btn btn-primary" type="submit">
                Close document
              </button>
                        </div>

                        <div class=" ml-3 text-right">
                            <button (click)="revertToPreviousPublishedData()" class="btn btn-primary" type="submit">
                                Revert to previous publish data
              </button>
                        </div>
                    </div>
                </form>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="FinancialMatrix" title="Financial Matrix" [disabled]="!formEnabled">
            <!-- All financial matrix data  -->
            <hr>
            <ng-template ngbTabContent>
              <div class="col">
                <ngb-tabset (tabChange)="saveOnFinTabChange($event.nextId)">
                   <ngb-tab id="companyStatistics" title="Company Statistics">
                    <ng-template ngbTabContent>
                      <div [ngStyle]="companyGridStyle" class="mt-3">
                        <ag-grid-angular style="width: 100%;height: 100%;clear: both;" (gridReady)="onCompanyGridReady($event)" class="ag-theme-balham"
                        [frameworkComponents]="frameworkComponents" [columnDefs]="companyColumnDefs" [rowData]="companyRowData"
                        [singleClickEdit]="true" [gridOptions]="gridOptions" [rowHeight]="40">
                        </ag-grid-angular>
                      
                      </div>
                      <div class="mt-3 mb-2">
                        <button (click)="saveCompanyStatistics('clear')" class="btn btn-outline-secondary mr-2"
                          [disabled]="formModel.readOnly">Clear All</button>
                          <!-- clearCompanyStatistics -->
                        <button (click)="resetMatrix('company')" [disabled]="!isResetEnabled"
                          class="btn btn-outline-secondary mr-2">Revert to Saved</button>
                        <button type="submit" class="btn btn-primary" (click)="onClose()">Close Document</button>
                        <button (click)="saveCompanyStatistics('save')" class="btn btn-primary pull-right"
                          [disabled]="formModel.readOnly">Save</button>
                      </div>
                    </ng-template>
                  </ngb-tab>
                  <ngb-tab id="earningSummary" title="Earning Summary">
                    <ng-template ngbTabContent>
                      <div class="row mt-2">
                        <div class="pl-5 pr-5">Display years</div>
                        <div class="col-2">
                          <label class="mr-2">First year</label>
                          <select name="firstYear" (change)="changeYear($event)" [(ngModel)]="selectedFirstYear">
                            <option *ngFor="let e of earningYears" [value]="e">{{ e }}</option>
                          </select>
                        </div>
                        <div class="col-2">
                          <label class="mr-2">Last year</label>
                          <select name="lastYear" (change)="changeYear($event)" [(ngModel)]="selectedLastYear">
                            <option *ngFor="let e of earningYears" [value]="e">{{ e }}</option>
                          </select>
                        </div>

                        <div class="col-6" >
                            <button class="btn btn-primary pull-right"  (click)="updateEstimateValuation(estimatesValuation)">Edit Estimates/Valuations</button>
                        </div>

                      </div>
                      <div class="row mt-2">
                        <div class="col-8">Check boxes in first column to include them in your notes. Check boxes in the "Q?" column to display quarterly data.</div>
                        <div class="col-4"> <strong>*E</strong>: Estimates &nbsp; <strong>*V</strong>: Valuations</div>
                      </div>
                      <div [ngStyle]="earningGridStyle">
                        <ag-grid-angular style="height: 100%; width: 100%; clear: both;" (gridReady)="onEarningGridReady($event)"  class="ag-theme-balham pt-3"
                        [columnDefs]="earningColumnDefs" 
                         [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions"
                         [rowData]="earningRowData" [singleClickEdit]="true" [groupHeaderHeight]="32" [headerHeight]="50" [rowHeight]="40"
                         >
                       </ag-grid-angular>
                      </div>
                      
                      
      
                      <div class="mt-3 mb-2">
                        <button (click)="saveEarningSummary('clear')" class="btn btn-outline-secondary mr-2"
                          [disabled]="formModel.readOnly">Clear All</button>
                        <button (click)="resetMatrix('earning')" [disabled]="!isResetEnabled"
                          class="btn btn-outline-secondary mr-2">
                          Revert to Saved
                        </button>
                        <button type="submit" class="btn btn-primary" (click)="onClose()">Close Document</button>
                        <button (click)="saveEarningSummary('save')" class="btn btn-primary pull-right"
                          [disabled]="formModel.readOnly">Save</button>
                      </div>
                    </ng-template>
                  </ngb-tab>
                </ngb-tabset>
              </div>
            </ng-template>
          </ngb-tab>
        <ngb-tab id="Comments" title="Comments" [disabled]="!formEnabled || formModel.actionNote.readOnly">
            <ng-template ngbTabContent>
                <div class="form-group pt-5 ml-5">
                    <div class="col-md-6 mt-4 row mx-auto">
                        <div class="col-sm-3 text-lg-right">
                            <label class="control-label " for="seeComments">Comments</label>
                        </div>

                        <div class="col-sm-9">
                            <pre class="form-control" id="seeComments" [innerHTML]="formModel.actionNote.comments" disabled style="opacity: 0.7; height: 200px"></pre>
                        </div>
                    </div>
                    <div class="col-md-6 mt-4 row mx-auto">
                        <div class="col-sm-3 text-lg-right">
                            <label class="control-label " for="addComments">Add
                Comments</label>
                        </div>
                        <div class="col-sm-9">
                            <textarea class="form-control" id="addComments" [(ngModel)]='comments' rows="5"></textarea>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3 row mx-auto">

                        <div class="col-sm-12 text-center">
                            <button type="submit" class="btn btn-primary mr-2" (click)="onAddComment()">Add Comments
              </button>
                            <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document
              </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="AuthorDocument" title="Author Document" [disabled]="!formEnabled || formModel.actionNote.readOnly">
            <ng-template ngbTabContent>
                <div class="form-group d-flex justify-content-center mt-5">
                    <fieldset class="border border-dark p-4 col-5">
                        <legend class="w-auto">Download Document</legend>
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-outline-primary" (click)="getBlankTemplate()">Download blank template</button>
                            <button class="btn btn-outline-primary" (click)="checkOutDocument()">Check out existing document </button>
                        </div>
                    </fieldset>
                </div>
                <div class="form-group d-flex justify-content-center mt-5">
                    <fieldset class="border border-dark p-4 col-5">
                        <legend class="w-auto">Check in document</legend>
                        <div class="input-group mb-3">
                            <div class="custom-file mr-5">
                                <input #fileInput (change)="onFileChange($event, fileInput)" class="custom-file-input" id="inputGroupFile02" type="file">
                                <label class="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">
                  {{checkInDocumentPath}}
                </label>
                            </div>
                            <div class="input-group-append">
                                <button (click)="checkIn()" [disabled]="!checkInDocument" class="input-group-text btn btn-primary" id="inputGroupFileAddon02">
                  Upload
                </button>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="form-group d-flex justify-content-center mt-5">
                    <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document</button>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab id="DistributionRecipients" title="Distribution Recipients" *ngIf="!enableTab('distribution')">
            <ng-template ngbTabContent>
                <div class="form-group d-flex justify-content-end mt-5">
                    <button type="submit" class="btn btn-primary mr-2" (click)="saveDistribution()">Save</button>
                    <button type="submit" class="btn btn-primary mr-2" (click)="onClose()">Close Document</button>
                </div>
                <div class="row">
                    <div class="form-group ml-5 col-2">
                        <div class="form-check mb-2" *ngFor="let recipient of formModel.actionNote.recipientList; let i = index">
                            <input class="form-check-input" type="checkbox" [(ngModel)]="recipient.selected" [id]="recipient.channelID" [disabled]="!recipient.editable">
                            <label class="form-check-label" [for]="recipient.channelID">
                {{recipient.channelDescription}}
              </label>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>

<ng-template #excelUpload let-c="close()" let-d="dismiss()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Confirm Upload</h4>
    </div>
    <div class="modal-body">
      <p>Do you want to upload the Financial Model ?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="(d)">No</button>
      <button type="button" class="btn btn-primary" (click)="(c)">Yes</button>
    </div>
  </ng-template>


  <ng-template #estimatesValuation let-modal let-c="close()" let-d="dismiss()">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> <Strong>Update Estimates/Valuations</Strong> </h4>
        <button type="button" class="close" (click)="d.dismiss('Cross click'); $event.stopPropagation()">
            <span aria-hidden="true">&times;</span>
          </button>      
      </div>
    <app-estimates 
    [docId]="formModel.actionNote.documentID"
     [issuerId]="formModel.actionNote.issuerID"
      [productId]="formModel.actionNote.productID"
       [isPopup]="true"
       (financialMatrixChange)="updateFinancialMatrix($event)"
       ></app-estimates>
  </ng-template>