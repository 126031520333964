<!-- <div class="container">
    <h3>Parameter</h3>
    <div class="drop-container" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="box" *ngFor="let item of parameters" cdkDrag>{{ item }}</div>
    </div>
  
    <h3>Estimates</h3>
    <div class="drop-container" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="box" *ngFor="let item of estimates" cdkDrag>{{ item }}</div>
    </div>
  
    <h3>Valuation</h3>
    <div class="drop-container" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="box" *ngFor="let item of valuations" cdkDrag>{{ item }}</div>
    </div>
  
    <div class="buttons">
      <button>button1</button>
      <button>button2</button>
      <button>button3</button>
    </div>
  </div>
   -->

   <!-- <div class="example-container">
    <h2>To do</h2>
  
    <div
      cdkDropList
      #todoList="cdkDropList"
      [cdkDropListData]="todo"
      [cdkDropListConnectedTo]="[doneList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of todo; track item) {
        <div class="example-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>
   -->
  <!-- <div class="example-container">
    <h2>Done</h2>
  
    <div
      cdkDropList
      #doneList="cdkDropList"
      [cdkDropListData]="done"
      [cdkDropListConnectedTo]="[todoList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      @for (item of done; track item) {
        <div class="example-box" cdkDrag>{{item}}</div>
      }
    </div>
  </div>
  
   -->
<!-- 
   hi
   <div class="kanban-board">
    <div class="column" *ngFor="let column of columns" [attr.id]="column.id">
      <h2>{{ column.name }}</h2>
      <div
        class="card"
        *ngFor="let card of column.cards"
        [attr.id]="card.id"
        draggable="true"
        (dragstart)="onDragStart($event, card)"
        (dragend)="onDragEnd($event)"
      >
        {{ card.content }}
      </div>
    </div>
  </div> -->

      <!-- <div class="kanban-board">
        <div class="column" id="todo">
            <h2>To Do</h2>
            <div class="card" draggable="true">Task 1</div>
            <div class="card" draggable="true">Task 2</div>
        </div>
        <div class="column" id="in-progress">
            <h2>In Progress</h2>
            <div class="card" draggable="true">Task 3</div>
        </div>
        <div class="column" id="done">
            <h2>Done</h2>
            <div class="card" draggable="true">Task 4</div>
        </div>
    </div> -->

    <!-- <div class="kanban-board">
      <div class="column" id="todo" >
          <h2>To Do</h2>
          <div class="card" *ngFor="let card of cards" draggable="true" >{{ card.content }}</div>
      </div>
      <div class="column" id="in-progress" >
          <h2>In Progress</h2>
          <div class="card" *ngFor="let card of cards" draggable="true" >{{ card  }}</div>
      </div>
      <div class="column" id="done" >
          <h2>Done</h2>
          <div class="card" *ngFor="let card of cards" draggable="true" >{{ card.content }}</div>
      </div>
    </div> -->
    

    <!-- <div cdkDropListGroup>
      <div class="example-container">
        <h2>To do</h2>
    
        <div
          cdkDropList
          [cdkDropListData]="todo"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          @for (item of todo; track item) {
            <div class="example-box" cdkDrag>{{item}}</div>
          }
        </div>
      </div>
    
      <div class="example-container">
        <h2>Done</h2>
    
        <div
          cdkDropList
          [cdkDropListData]="done"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          @for (item of done; track item) {
            <div class="example-box" cdkDrag>{{item}}</div>
          }
        </div>
      </div>
    </div> -->

    <!-- <div cdkDropListGroup>
      <div class="example-container">
        <h2>To do</h2>
        <div
          cdkDropList
          [cdkDropListData]="todo"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of todo; trackBy: trackByFn" class="example-box" cdkDrag>{{item}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h2>Done</h2>
        <div
          cdkDropList
          [cdkDropListData]="done"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of done; trackBy: trackByFn" class="example-box" cdkDrag>{{item}}</div>
        </div>
      </div>
    </div>
     -->
     
     <div class="mt-5 text-center">

      <h1 *ngIf="!isPopup" class="mb-3">Estimates and Valuations Default Parameter List</h1>

      
     <div cdkDropListGroup>
      <div class="example-container">
        <h1 class="font-weight-bold">  Parameter List</h1>
        <div
          cdkDropList
          [cdkDropListData]="parameters"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of parameters; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h1 class="font-weight-bold">Estimates</h1>
        <div
          cdkDropList
          [cdkDropListData]="estimates"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of estimates; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    
      <div class="example-container">
        <h1 class="font-weight-bold">Valuations</h1>
        <div
          cdkDropList
          [cdkDropListData]="valuations"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div *ngFor="let item of valuations; trackBy: trackByFn" class="example-box" cdkDrag>{{item.displayName}}</div>
        </div>
      </div>
    </div>
    </div>
  <div class="form-group row justify-content-center col-sm-12">
      <div class="text-right">
          <button (click)="updateParameter()" class="btn btn-primary" type="submit" >Update</button>
      </div>
      <div class=" ml-3 text-right">
          <button (click)="resetParameter()" class="btn btn-primary" type="submit">Reset</button>
      </div>

      <div *ngIf="isPopup" class=" ml-3 text-right">
        <button (click)="resetToDefaultParameter()" class="btn btn-primary" type="submit">Reset To Default</button>
    </div>
  </div>
    
    