import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertService, ApiService, AuthenticationService, DataService } from '../../_services';
import { DocumentModel } from './document.model';
import { NgForm, FormControl, FormGroup } from '@angular/forms';
import { UtilityClass } from 'src/app/common/utility';
import { BehaviorSubject } from 'rxjs';
import { CheckboxRendererComponent, CustomHeaderRendererComponent, CustomUnitRendererComponent } from "../../_renderers";

import _ from 'lodash';
import { companyColumnDefs, fixedEarningSummaryColumns } from './commonData';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HttpHeaders } from '@angular/common/http';
import { NotificationService } from 'src/app/_services/notification.service';


@Component({
  selector: 'app-select-bindings',
  templateUrl: './companyreport.component.html',
  styleUrls: ['./document.component.scss']
})
export class CompanyreportComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  queryParams: any;
  selectedTab: String;
  showModel: any;
  comments: string;
  loggedInUserName: string;
  checkInDocument: any;
  checkInDocumentPath: string;
  defaultReportType: any;
  @ViewChild('documentForm')
  documentForm: NgForm
  selectedFinTab: string;
  isResetEnabled: boolean = true;
  companyGridApi: any;
  companyGridColumnApi: any;
  companyRowData: any;
  companyColumnDefs: any;
  frameworkComponents: any
  gridOptions: any;
  earningGridApi: any;
  earningColumnDefs: any;
  selectedFirstYear: any;
  earningSummary: any;
  selectedLastYear: any;
  yearArray: any;
  earningGridColumnApi: any;
  earningRowData: any;
  earningGridStyle = {
    height: "400px",
  };  
  companyGridStyle = {
    height: "400px",
  };  
  revertCompanyStatistics: any;
  revertCompanyStatisticsClone: any;

  allIssuers: any;
  earningYears: any;
  earningValues: any;
  earningDynamicColums: any;
  resetMatrixValue = new BehaviorSubject(false);
  gridParams: any;

  financialMatrixCurrency = [
    'CAD',
    'USD',
    'EUR',
    'GBP',
    'GBp',
    'CHF',
    'SEK',
    'DKK',
    'NOK',
    'CNY',
    'JPY',
    'KRW',
    'HKD',
    'TWD',
    'SCD',
    'AUD',
    'ZAR',
    'BRL'
  ]

  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private zone: NgZone

  ) {
    const self = this;
    this.showModel = true;
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.route.params.subscribe(params => (this.params = params));
    this.route.queryParams.subscribe(params => this.queryParams = params);
    this.companyColumnDefs = companyColumnDefs;
    this.earningColumnDefs = fixedEarningSummaryColumns;
    this.frameworkComponents = {
      checkBoxRenderer: CheckboxRendererComponent,
      agColumnHeader: CustomHeaderRendererComponent,
      unitRenderer: CustomUnitRendererComponent,
    };
    this.gridOptions = {
      onCellValueChanged: function () {
        this.isResetEnabled = true;
      },

    };
    this.checkInDocumentPath = 'Choose file';
    this.selectedTab = 'Metadata';
    this.selectedFinTab = "companyStatistics";
  }

  createDocument(event): void {
    const requestBody = {
      personid: this.authService.user.value.personID,
      issuerid: event,
    };
    this.apiService
      .createDocument('companyreport', requestBody)
      .subscribe(response => {
        const queryParams = {
          documentid: response.data.documentID,
          productid: response.data.productID,
          issuerid: response.data.issuerID
        };
        this.router.navigate(['/companyreport/open'], { queryParams });
        this.setInitialData(response);
      });
  }
  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case 'Metadata':
          this.onSave();
          break;
        case 'Comments':
          this.onAddComment();
          break;
          case 'FinancialMatrix':
        if (this.selectedFinTab == "companyStatistics") {
          this.saveCompanyStatistics("update");
          this.selectedFinTab = "companyStatistics";
        } else {
          this.saveEarningSummary("update");
          this.selectedFinTab = "earningSummary";
        }
        case 'AuthorDocument':
          break;
        case 'DistributionRecipients':
          this.saveDistribution();
          break;
        default:
          break;
      }
    }
    this.selectedTab = tab;
  }

  saveOnFinTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      if (this.selectedFinTab == "companyStatistics") {
        this.saveCompanyStatistics("update");
      } else {
        this.saveEarningSummary("update");
      }
    }

    this.selectedFinTab = tab;
  }
  reset() {
    this.formModel.actionNote.status.primaryAuthors = false;
    this.formModel.actionNote.status.productsStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.prodIssuersStatus = false;
    this.formModel.actionNote.status.secondaryIssuers = false;
    this.formModel.actionNote.status.secondaryIndustries = false;
    this.formModel.actionNote.status.primaryIssuers = false;
    this.formModel.actionNote.status.distributionStatus = false;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = false;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = false;
    this.formModel.actionNote.status.companyEarningsValueStatus = false;
  }
  onSave() {
    this.formModel.actionNote.status.primaryAuthors = true;
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.status.secondaryIssuers = true;
    this.formModel.actionNote.status.prodIssuersStatus = true;
    this.formModel.actionNote.status.companyStatisticsStatus = true;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = true;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = true;
    this.formModel.actionNote.status.companyEarningsValueStatus = true;


    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    if(this.selectedTab === 'Metadata'){
      this.updateFinancialValue(this.formModel.actionNote.financialMatrix.statistics, 'Price', this.formModel.actionNote.currentPrice);
    }
    
    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe({next:(res)=>{
        this.setCurrentPrice(res.data.financialMatrix);
      }, error: ()=>{}, complete: () => this.reset()});
  }

  onSaveBypassInterceptor() {
    this.formModel.actionNote.status.primaryAuthors = true;
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.status.secondaryIssuers = true;
    this.formModel.actionNote.status.prodIssuersStatus = true;
    this.formModel.actionNote.status.companyStatisticsStatus = true;
    this.formModel.actionNote.status.companyEarningsHeaderStatus = true;    
    this.formModel.actionNote.status.companyEarningsParameterStatus = true;
    this.formModel.actionNote.status.companyEarningsValueStatus = true;


    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(
        {next:(res)=>{
          this.setCurrentPrice(res.data.financialMatrix);
        }, error: () =>{},complete: () => this.reset()
    });

  }

  

  onClose() {

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    if ( !this.formModel.actionNote.readOnly ) {
      this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(() => {
        this.apiService
          .documentAction('unlockdocument', {
            productid: this.formModel.actionNote.productID,
            personid: this.authService.user.value.personID
          })
          .subscribe(data => this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage } }));
      }, null, () => this.reset());
    } else {
      this.router.navigate(['/documents'], { queryParams: { stage: this.dataService.selectedStage }});
    }



  }
  getComment(): string {
    const timeStamp = new Date();
    let comment = '';
    const previousComments = this.formModel.actionNote.product.comments || '';
    const currentComments = this.comments ? `${this.loggedInUserName} ${timeStamp.toString().substr(0, 33)}  :  ${this.comments}` : '';
    if (previousComments === '') {
      comment = `${currentComments}`;
    } else {
      comment = `${previousComments} <br />${currentComments}`;
    }
    this.formModel.actionNote.comments = comment;
    return comment;
  }
  onAddComment() {
    this.formModel.actionNote.status.productsStatus = true;
    this.formModel.actionNote.product.comments = this.getComment();

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService
      .updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(data => this.comments = '', null, this.reset);
  }
  setInitialData({ data }) {
    this.formModel.actionNote = data;
    this.formModel.actionNote.prodIssuers.defaultCurrency = this.formModel.actionNote.prodIssuers.defaultCurrency || 'USD';
    this.formModel.selectedPrimaryIssuer = this.formModel.actionNote.prodIssuers.issuer.issuerID;
    this.formEnabled = true;
    this.formModel.actionNote.personID = this.authService.user.value.personID;
    this.formModel.primaryAuthors = data.allAnalyst;
    this.formModel.actionNote.prodIssuers.reportTypeId = this.formModel.reportTypeList.some(function (value) { return value.id === data.prodIssuers.reportTypeId }) ?
      data.prodIssuers.reportTypeId : (this.defaultReportType ? this.defaultReportType.id : null);
    this.yearArray = [];
    this.earningSummary = data.financialMatrix.earnings_values;
    this.revertCompanyStatistics = this.formModel.actionNote.financialMatrix;
    this.revertCompanyStatisticsClone = _.cloneDeep(this.revertCompanyStatistics);
    this.earningYears = _.keysIn(this.earningSummary);
    console.log(this.earningYears);
    if(this.allIssuers)
      this.allIssuers = this.allIssuers.filter(e=> e.issuerID !== data.prodIssuers.issuerID )
   
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);

  }

  ngOnInit(): void {
    this.authService.user.subscribe(user => this.loggedInUserName = user.displayName);
    this.apiService.getPrimaryIssuers(this.authService.user.value.personID).subscribe(({ data }) => (this.formModel.primaryIssuers = data));
    this.apiService.getReportTypeSeries('reportseries', true).subscribe(({ data }) => this.formModel.reportSeriesList = data);
    this.apiService.getReportTypeSeries('reporttype', true).subscribe(({ data }) => {
      this.formModel.reportTypeList = data;
      this.defaultReportType = data.find(row => row.value === 'Company-Specific');
      this.apiService.getAllIssuers().subscribe(({data})=>{
        this.allIssuers = data;
      });
      if (this.params.action === 'open') {
        const requestBody = { ...this.queryParams, personid: this.authService.user.value.personID };
        this.apiService.openDocument('companyreport', requestBody).subscribe(data => {
          this.setInitialData(data);
        }, err => this.router.navigate(['/documents']));
      }
    });
  }
  getBlankTemplate(): void {
    this.apiService.downloadDocument('companyreport', this.formModel.actionNote.documentID).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/msword' });
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }

  checkOutDocument(): void {
    this.apiService.downloadDocument('downloaddocument', this.formModel.actionNote.documentID).subscribe(response => {
      const blob = new Blob([response.body], { type: 'application/msword' });
      let util = new UtilityClass();
      util.downloadDocument(response, this.formModel.actionNote.documentID);
    });
  }
  onFileChange(event, fileInput): void {
    this.checkInDocument = fileInput.files[0];
    this.checkInDocumentPath = fileInput.files[0].name;
    fileInput.value = null;
  }
  checkIn(): void {
    const formData = new FormData();
    formData.append('uploadDocument', this.checkInDocument);
    this.apiService.uploadDocument(this.formModel.actionNote.documentID, formData).subscribe(() => {
      this.checkInDocument = null;
      this.checkInDocumentPath = 'Choose file';
    });
  }
  changexlxs(event, file, content): void {
    this.modalService.open(content).result.then(
      (result) => {

        const formData = new FormData();
        formData.append("excelDocument", file.files[0]);
        formData.append('productid', this.formModel.actionNote.productID?.toString() || '');
        formData.append('personid', this.formModel.actionNote.personID?.toString() || '');
        // formData.append('currency', this.formModel.actionNote.product.defaultDisplayCurrency || '');
  

        // this.apiService.uploadFinancialModel(formData, this.formModel.actionNote.productID, this.formModel.actionNote.personID, this.formModel.actionNote.product.defaultDisplayCurrency).subscribe(
          this.apiService.uploadFinancialModel(formData).subscribe(
          (response) => {
            this.importFinancialMatrix();
            file.value = null;
          },
          (error) => {
            file.value = null;
          },
        );
      },
      (reason) => {
        file.value = null;
      }
    );
  }
  enableTab(tab): boolean {
    return !(tab === 'distribution' && this.formModel.actionNote.stageID === 4);
  }

  importFinancialMatrix(): void {
    const requestBody = {
      productid: this.formModel.actionNote.productID,
      issuerid:this.formModel.actionNote.issuerID,
    };

    this.apiService.importFinancialMatrix(requestBody,  { headers: new HttpHeaders({ 'X-Skip-Loader': 'true' }) }).subscribe((response) => {
      this.formModel.actionNote.financialMatrix = response.data;
      this.revertCompanyStatistics = JSON.parse(JSON.stringify(this.formModel.actionNote.financialMatrix));
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    });
  }

  importFinancialMatrixManual(): void {
    const requestBody = {
      productid: this.formModel.actionNote.productID,
      issuerid:this.formModel.actionNote.issuerID,
    };

    this.apiService.importFinancialMatrix(requestBody).subscribe((response) => {
      this.formModel.actionNote.financialMatrix = response.data;
      this.revertCompanyStatistics = JSON.parse(JSON.stringify(this.formModel.actionNote.financialMatrix));
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    });
  }

  revertToPreviousPublishedData():  void {
    this.apiService.revertFinancialMatrixPreviousUpload(this.formModel.actionNote.issuerID).subscribe({
      next: (res)=>{
                this.setFinancialMatrix(res.data);
      },
      complete: ()=>{
        // this.setFinancialMatrix(this.revertCompanyStatisticsClone);
        this.notificationService.showSuccess('Successfully Reverted to Previous Publish Data');
      }
    });
    // 
    // console.log(this.revertCompanyStatisticsClone);
    
  }

  saveDistribution() {
    this.formModel.actionNote.status.distributionStatus = true;

    const targetPrice = new FormControl(this.formModel.actionNote.prodIssuers.targetPrice);
    const financialValue = new FormControl(this.formModel.actionNote.currentPrice);
    const singleReportPrice = new FormControl(this.formModel.actionNote.product.singleReportPrice);
    if ( !this.formModel.priceValidator( 'fairPrice', {control: targetPrice}) || !this.formModel.priceValidator( 'currentPrice', {control: financialValue}) || !this.formModel.priceValidator( 'singleReportPrice', {control: singleReportPrice}) ) {
      this.alertService.message('', 'Save failed. Please correct data on Metadata tab.');
      return;
    }

    this.apiService.updateDocument('companyreport', { companyNote: this.formModel.actionNote, industryNote: {} })
      .subscribe(null, null, this.reset);
  }

  onFinTabChange(event){
    this.selectedFinTab = event;
  }

  clearFinMartix(param){
    if (param === 'companystatistics') {
     this.revertCompanyStatistics.statistics = this.formModel.actionNote.financialMatrix.statistics;
     this.apiService.clearFinancialMatrix(param, this.formModel.actionNote.financialMatrix).subscribe(response =>{
       this.formModel.actionNote.financialMatrix = JSON.parse(JSON.stringify(response));
       this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
     });
    }
    else if (param === 'earningsummary'){
      
      this.revertCompanyStatistics.earnings_values = this.formModel.actionNote.financialMatrix.earnings_values;
      this.revertCompanyStatistics.earnings_parameter = this.formModel.actionNote.financialMatrix.earnings_parameter;
      this.apiService.clearFinancialMatrix(param, this.formModel.actionNote.financialMatrix).subscribe(response =>{
       this.formModel.actionNote.financialMatrix = response;
       
       this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
       this.onEarningGridReady(this.gridParams)
     });
    }
   }

   saveCompanyStatistics(param){
    if(param === 'clear'){
      this.isResetEnabled = true;
      this.clearFinMartix('companystatistics');
    }
    else if(param === 'update'){
      this.onSaveBypassInterceptor();
    }
    else{
      this.onSave();
    }
  }
  saveEarningSummary(param){
    if(param === 'clear'){
      this.isResetEnabled = true;
      this.clearFinMartix('earningsummary');
    }
    else if(param === 'update'){
      this.onSaveBypassInterceptor();
    }
    else{
      this.onSave();
    }
  }

   onCompanyGridReady(params) {
    // this.isResetEnabled = false;
    this.companyGridApi = params.api;
    this.companyGridColumnApi = params.columnApi;
    this.companyGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 320;
    /*Get the HOT length*/
    const hotData = this.companyRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
      
    // this.companyGridStyle.height = `${availableHeight}px`;
  }
  resetMatrix(type): void {
    if (type === 'company') {
      this.formModel.actionNote.financialMatrix.statistics = this.revertCompanyStatistics.statistics;
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
    } else if (type === "earning") {
      this.formModel.actionNote.financialMatrix.earnings_parameter = this.revertCompanyStatistics.earnings_parameter;
      this.formModel.actionNote.financialMatrix.earnings_values = this.revertCompanyStatistics.earnings_values;
      this.setFinancialMatrix(this.formModel.actionNote.financialMatrix);
      this.onEarningGridReady(this.gridParams)
    }
    
  }

   setFinancialMatrix(data, isUpdateRevertData?:any) {
    // if(isUpdateRevertData){
    //   this.revertCompanyStatisticsClone = data;
    // }

    this.companyRowData = data.statistics;
    this.setCurrentPrice(data);
    this.earningRowData = data.earnings_parameter;
    this.earningDynamicColums =  data.earnings_parameter_header;
    this.earningValues = data.earnings_values;
    this.earningYears = _.keysIn(data.earnings_values);
    console.log(this.earningYears);
    this.selectedFirstYear = this.selectedLastYear = null;
    this.yearArray = [];
    this.earningDynamicColums.forEach((value) => {
      
      this.yearArray.push(value.periodYear);
      if (value.selected && !this.selectedFirstYear) {
        this.selectedFirstYear = value.periodYear;
      } else if (value.selected) {
        this.selectedLastYear = value.periodYear;
      }
    });
  }

  setCurrentPrice(data){
       const priceObject = data.statistics.find(stat => stat.parameterName === 'Price');    
        this.formModel.actionNote.currentPrice  = priceObject.financialValue;

  }


   updateFinancialValue = (array, parameter, newValue) => {
    const item = array.find(stat => stat.parameterName === parameter);
    if (item) {
      item.financialValue = newValue;
    }
  };
  
 
  changeYear(event): void {
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()))
    this.earningGridApi.sizeColumnsToFit();
    this.isResetEnabled = true;
    this.earningDynamicColums.forEach((value) => {
      if(value.periodYear>= this.selectedFirstYear && value.periodYear <= this.selectedLastYear){
          value.selected = true
      }
      else{
        value.selected = false
      }
      
    })
  }
  getDynamicTable(): any {
    /*Set dynamic clumn headers*/
    let earning_values = this.earningValues;
    const columnHeaders = [];
    
    this.earningDynamicColums.forEach((value) => {
      columnHeaders.push({
        headerName: value.periodYear,
        children: [
          {
            headerName: "Q1",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName))?.q1Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q1Value = params.newValue
                return true
               }
              });
             
               
              
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q2",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q2Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q2Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q3",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q3Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q3Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "Q4",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).q4Value;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.q4Value = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "FY",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).fyValue;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.fyValue = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
          {
            headerName: "CY",
            minWidth: 100,
            hide: value.periodYear < this.selectedFirstYear || value.periodYear > this.selectedLastYear,
            headerComponentParams: {
              __metadata__: value,
              showSelect: true,
              showRadio: true,
              selectValues: ["", "E", "A"],
            },
            valueGetter: function (params) {
              return earning_values[value.periodYear].find((data) => (data.parameterName === params.data.parameterName)).cyValue;
            },
            valueSetter: function (params) {
              earning_values[value.periodYear].forEach(element => {
               if(params.data.parameterName === element.parameterName){
                element.cyValue = params.newValue
                return true
               }
              });
            },
            editable: true,
            cellEditor: "agTextCellEditor",
          },
        ],
      });
    });
    
    return columnHeaders;
  }

  getYearArray(event): void {
    return this.yearArray.filter((value) => (event === "firstYear" && value <= this.selectedLastYear) || (event === "lastYear" && value >= this.selectedFirstYear));
  }

  onEarningGridReady(params) {
    this.gridParams = params;
    // this.isResetEnabled = false;
    this.earningGridApi = params.api;
    this.earningGridColumnApi = params.columnApi;
    this.earningGridApi.setColumnDefs(this.earningColumnDefs.concat(this.getDynamicTable()));
    this.earningGridApi.reset = this.resetMatrixValue;
    params.api.sizeColumnsToFit();
    let availableHeight;
    let hotLength = 0;
    availableHeight = window.innerHeight - 370;
    /*Get the HOT length*/
    const hotData = this.earningRowData;
    if (hotData.length > 0) {
      hotLength = hotData.length;
    }
    /*Calculate the new height for HOT
     * Here, 31 is the height of each row and
     * 48 is the padding left in the bottom
     */
    const newHeight = hotLength * 28 + 150;
    if (newHeight < availableHeight) {
      availableHeight = newHeight;
    }
    // this.earningGridStyle.height = `${availableHeight}px`;
  }

  modalRef;
  updateEstimateValuation(content){
    const modalOptions: NgbModalOptions = {
      size: 'xl',
      // windowClass: 'modal-xl',
      // centered: true,
    };
    this.zone.run(() => {
      this.modalRef = this.modalService.open(content, modalOptions);
    });

    // this.modalRef = this.modalService.open(content, modalOptions);

    // this.modalService.open(content, modalOptions);
  }

  financialMatrix: any;

  updateFinancialMatrix(financialMatrix: any) {
    if (this.modalRef) {
      this.modalRef.close();
    }
  
    // this.financialMatrix = financialMatrix;
    this.formModel.actionNote.financialMatrix = financialMatrix;
    this.setFinancialMatrix(financialMatrix, true);
    // Perform any additional actions with the updated financialMatrix if needed
  }



   


}
