import {   CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
 } from "@angular/cdk/drag-drop";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ApiService } from "src/app/_services";
import _ from 'lodash';


// interface Card {
//   id: string;
//   content: string;
// }

// interface Column {
//   id: string;
//   name: string;
//   cards: Card[];
// }

interface Card {
  id: string;
  content: string;
  column: string;
}


@Component({
    templateUrl: 'estimates.admin.component.html',
    styleUrls: ['estimates.admin.component.scss'],
    selector:'app-estimates',
  })
export class EstimatesComponent implements OnInit{

    parameters = ['price', 'marketcap', 'p/e', 'wk52Low'];
    parametersCopy;
    estimates = ['wk52High'];
    valuations = ['AverageDayVolume'];
    parameterList:any;

    @Input() docId: string;
    @Input() productId: string;
    @Input() issuerId: string;
    @Input() isPopup: boolean = false;
    @Output() financialMatrixChange = new EventEmitter<any>();

    financialMatrix:any;

    constructor(private apiService: ApiService, private changeDetectorRef :ChangeDetectorRef) { }

    ngOnInit() {
      this.getEarningSummary();
    }

    getEarningSummary() {
      const apiCall = this.productId ? 
      this.apiService.getProductEarningSummaryCategory(this.productId, this.issuerId):
        this.apiService.getDefaultEarningSummaryCategory();
        
    
      apiCall.subscribe({
        next: (res: any) => this.handleEarningSummaryResponse(res),
        complete: () => this.removeDuplicates()
      });
    }
    
    handleEarningSummaryResponse(res: any) {
      this.parameterList = res;
      this.parameters = res.allParameters;
      this.parametersCopy = _.cloneDeep(this.parameters);
      this.estimates = res.estimates;
      this.valuations = res.valuation;
    }
    

    removeDuplicates() {
      const estimateIds = new Set(this.estimates.map((item: any) => item.parameterName));
      const valuationIds = new Set(this.valuations.map((item: any) => item.parameterName));
      const allIds = new Set([...estimateIds , ...valuationIds]);
      this.parameters = this.parameters.filter((param: any) => 
        !allIds.has(param.parameterName)
      );
    }

    resetToDefaultParameter(){
      this.apiService.getDefaultEarningSummaryCategory().subscribe({
        next: (res: any) => this.handleEarningSummaryResponse(res),
        complete: () => this.removeDuplicates()
      });
    }

    resetParameter(){
      this.parameterList.estimates = [];
      this.parameterList.valuation = [];
      this.valuations = [];
      this.estimates = [];
      this.parameters =  _.cloneDeep(this.parametersCopy);
      // this.apiService.setEarningSummaryCategory(this.productId, this.parameterList).subscribe({
      //   next: (res:any) =>{
      //     if(this.productId){
      //       this.financialMatrix = res.data;
      //       this.financialMatrixChange.emit(this.financialMatrix);
      //     }
      //       this.getEarningSummary();
      //   }
      // });
    }
    updateParameter(){
      this.parameterList.productID = this.productId;
      this.parameterList.issuerID = this.issuerId;
      this.apiService.setEarningSummaryCategory(this.productId, this.parameterList).subscribe({
        next: (res:any) =>{
          if(this.productId){
            this.financialMatrix = res.data;
          }
            this.getEarningSummary();
        },
        complete: ()=>{
          if(this.productId){
          this.financialMatrixChange.emit(this.financialMatrix);
          }
        }
      });
    }
  

    drop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
    }

    trackByFn(index: number, item: any): any {
      return index;
    }

    ngAfterViewChecked() {
      this.changeDetectorRef.detectChanges();
    }
    

}